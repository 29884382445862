import "../styles/index.scss";
import "slick-carousel/slick/slick.scss";
import "jquery-ui-dist/jquery-ui.min.css";
import "slick-carousel/slick/slick-theme.scss";

import $ from "jquery";
import "slick-carousel";
import "jquery-ui-dist/jquery-ui";

$(document).ready(function () {
  function initializeSliders() {
    // Удаляем ранее инициализированные слайдеры перед повторной инициализацией
    if ($(".roadmap-description").hasClass("slick-initialized")) {
      $(".roadmap-description").slick("unslick");
    }

    if (
      $(
        ".mobile-roadmap-description .roadmap-description__row-container"
      ).hasClass("slick-initialized")
    ) {
      $(
        ".mobile-roadmap-description .roadmap-description__row-container"
      ).slick("unslick");
    }

    // Проверка ширины окна и инициализация соответствующего слайдера
    if ($(window).width() < 1200) {
      console.log("Mobile view initialized");
      $(".feature-card__button-text--mobile").on("click", function (event) {
        event.preventDefault();
        $(".features-slider__row").css("display", "block");
        $(".slider_top_mobile").children("img").css("display", "none");
      });

      setTimeout(function () {
        $(".roadmap-description").slick({
          arrows: true,
          infinite: false,
          speed: 1000,
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
        });

        $(
            ".mobile-roadmap-description .roadmap-description__row-container"
        ).slick({
          arrows: false,
          infinite: true,
          autoplay: true,
          speed: 1000,
          slidesToShow: 1,
          dots: true,
          waitForAnimate: true,
        });
      }, 15 * 1000);



      $(".roadmap-description").on(
        "beforeChange",
        function (event, slick, currentSlide, nextSlide) {
          let $title = "roadmap-description__title",
            $description = "roadmap-description__row-container";
          let arr1 = $(".mobile-roadmap-description" + " ." + $title);
          let arr2 = $(".mobile-roadmap-description" + " ." + $description);
          console.log($(arr1[currentSlide]));
          $(arr1[currentSlide]).removeClass($title + "--active");
          $(arr2[currentSlide]).removeClass($description + "--active");
          $(arr1[nextSlide]).addClass($title + "--active");
          $(arr2[nextSlide]).addClass($description + "--active");
        }
      );
    } else {
      console.log("Desktop view initialized");
    }
  }

  // Инициализация слайдеров при загрузке страницы
  initializeSliders();

  // Отслеживание изменения размера окна
  $(window).resize(function () {
    console.log("Window width: " + $(window).width());
    initializeSliders();
  });

  // Ваш остальной код
  $(".menu__item--wallet").click(function () {
    const ethEnabled = async () => {
      console.log("isConnected", window.ethereum.isConnected());
      if (window.ethereum) {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        console.log(getAddress());
        saveAddress(accounts[0]);
        console.log(getAddress());
        // window.web3 = new Web3(window.ethereum);
        checkConnection();
        return true;
      }
      return false;
    };

    ethEnabled();
  });

  checkConnection();

  function checkConnection() {
    console.log("check");
    if (isConnected()) {
      $(".menu__item--wallet").hide();
      $(".dashboard-header__column").show();

      let address = getAddress();

      $(".dashboard-header-info__address").text(
        address[0] +
          address[1] +
          address[2] +
          "..." +
          address[address.length - 4] +
          address[address.length - 3] +
          address[address.length - 2] +
          address[address.length - 1]
      );
    } else {
      $(".dashboard-header__column").hide();
    }
  }

  function isConnected() {
    return getAddress() !== null;
  }

  function saveAddress(address) {
    window.localStorage.setItem("address", address);
  }

  function getAddress() {
    return window.localStorage.getItem("address");
  }

  $(".app-container .features-slider__row").slick({
    autoplay: true,
    dots: false,
    arrows: false,
    infinite: true,
    autoplaySpeed: 1500,
    speed: 100,
    fade: true,
    cssEase: "linear",
  });

  $(".slider-button__pause").click(function () {
    $(".app-container .features-slider__row").slick("slickPause");
    $(this).hide();
    $(".slider-button__play").show();
  });

  $(".slider-button__play").click(function () {
    $(".app-container .features-slider__row").slick("slickPlay");
    $(this).hide();
    $(".slider-button__pause").show();
  });

  $(".metaverse__slider").slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    variableWidth: true,
    arrows: false,
    infinite: false,

    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          dots: true,
          variableWidth: false,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          variableWidth: true,
        },
      },
    ],
  });

  let isOpenedMenu = false;

  $(".menu-button").click(function () {
    if (isOpenedMenu) {
      closeMenu();
    } else {
      $(".mobile-menu").show();
      $("body").addClass("popup");
      isOpenedMenu = true;
    }
  });

  $(".menu-item").click(function (e) {
    e.preventDefault();
    closeMenu();
    scrollTo($(this).attr("href"));
  });

  $(".introduce__button, .menu__item--link").click(function (e) {
    e.preventDefault();
    scrollTo($(this).attr("href"));
  });

  function closeMenu() {
    $(".mobile-menu").hide();
    $("body").removeClass("popup");
    isOpenedMenu = false;
  }

  function scrollTo(id) {
    $([document.documentElement, document.body]).animate(
      {
        scrollTop: $(id).offset().top,
      },
      2000
    );
  }

  $(".map-item__step--button").click(function () {
    let main = $(this).parent();
    setActiveInRoadmap(main.data("id"));
  });

  function setActiveInRoadmap(elementId) {
    let elements = $(".map-item");
    let currentActiveId = $(".map-item--current").data("id");
    console.log(elementId, currentActiveId);

    $(".roadmap-description__container--active").removeClass(
      "roadmap-description__container--active"
    );
    $('.roadmap-description__container[data-id="' + elementId + '"]').addClass(
      "roadmap-description__container--active"
    );

    if (currentActiveId <= elementId) {
      elements.each((i, e) => {
        let $element = $(e);
        let currentId = $element.data("id");

        if (currentId > elementId) {
          return;
        }

        $element.removeClass("map-item--current");
        $element.addClass("map-item--active");
        if (currentId === elementId) {
          $element.addClass("map-item--current");
        }
      });
    } else {
      console.log("WAY ANOTHER");

      console.log($(elements.get().reverse()));
      $(elements.get().reverse()).each((i, e) => {
        let $element = $(e);
        let currentId = $element.data("id");

        if (currentId < elementId) {
          return;
        }
        console.log(currentId, elementId);

        $element.removeClass("map-item--active");
        $element.removeClass("map-item--current");
        if (currentId === elementId) {
          $element.addClass("map-item--active");
          $element.addClass("map-item--current");
        }
      });
    }
  }

  $(".features-slider__pc").slick({
    arrows: true,
    infinite: false,
    dots: true,
    slidesToShow: 1,
    autoplay: false,
    speed: 1000,
  });

  var buttonPlayVideo = $(".features-slider__row--play"),
    video = $(".features-slider__row--video");

  buttonPlayVideo.on("click", function (event) {
    event.preventDefault();
    if (video.get(0).paused) {
      video.trigger("play");
      video.css("cursor", "pointer");
      buttonPlayVideo.css("opacity", 0);
    } else {
      video.trigger("pause");
      video.css("cursor", "default");
      buttonPlayVideo.css("opacity", 1);
    }
  });

  video.on("click", function (event) {
    event.preventDefault();
    if (video.get(0).play) {
      video.trigger("pause");
      video.css("cursor", "default");
      buttonPlayVideo.css("opacity", 1);
    }
  });

  var faqTrigger = $(".faq-question"),
    faqItems = $(".faq-items"),
    lenFaqItems = faqItems.length,
    prevButton = $(".faq-prev-button"),
    nextButton = $(".faq-next-button"),
    moreButton = $(".faq-more-button");

  faqTrigger.on("click", function (event) {
    event.preventDefault();
    $(this)
      .next(".faq-answer")
      .slideToggle(200)
      .end()
      .parent("li")
      .toggleClass("content-visible")
      .end()
      .children("p")
      .toggleClass("color-gray")
      .end()
      .children("div")
      .toggleClass("answer_image_visible");
  });

  moreButton.on("click", function (e) {
    e.preventDefault();
    let activeItem = $(".faq-items--active");
    let activeItemIndex = activeItem.attr("data-id");
    let nextItem = $(faqItems[activeItemIndex]);

    activeItem.removeClass("faq-items--active");
    nextItem.toggleClass("faq-items--active");
    moreButton.css("display", "none");
    prevButton.css({ opacity: 1, cursor: "pointer" });
    nextButton.css("display", "block");
  });

  nextButton.on("click", function (event) {
    event.preventDefault();
    let activeItem = $(".faq-items--active");
    let activeItemIndex = activeItem.attr("data-id");
    let nextItem = $(faqItems[activeItemIndex]);
    if (activeItemIndex < lenFaqItems) {
      prevButton.css("color", "#FF67A2");
      activeItem.removeClass("faq-items--active");
      nextItem.toggleClass("faq-items--active");
      if (activeItemIndex == lenFaqItems - 1) {
        nextButton.css("color", "#FFFFFF");
      }
    }
  });

  prevButton.on("click", function (event) {
    event.preventDefault();
    let activeItem = $(".faq-items--active");
    let activeItemIndex = activeItem.attr("data-id");
    let prevItem = $(faqItems[activeItemIndex - 2]);
    if (activeItemIndex > 1) {
      nextButton.css("color", "#FF67A2");
      activeItem.removeClass("faq-items--active");
      prevItem.toggleClass("faq-items--active");
      if (activeItemIndex == 2) {
        prevButton.css({ opacity: "0", cursor: "pointer" });
        nextButton.css("display", "none");
        moreButton.css("display", "block");
      }
    }
  });
});
